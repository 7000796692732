.streambox__controls {
  button {
    border: none;
    color: white;
    background: var(--c-magenta-400);
    padding: .75rem 1.25rem 0.5rem;
    border-radius: 8px;
    text-shadow: 2px 1px var(--c-magenta-600);
    cursor: pointer;

    opacity: .4;

    &:hover,
    &:focus {
      opacity: 1;
    }

    svg {
      height: 1rem;
    }
  }
}