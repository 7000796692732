* {
  box-sizing: border-box;
}

/* Scrollbars */
body {
  scrollbar-color: #495460 hsla(0,0%,100%,0);
  scrollbar-width:thin;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(56, 64, 80, 0.3);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0,0,0,0.10),inset 0 -1px 0 rgba(0,0,0,0.07);
}

/* Livepage layout */
body {
  margin: 0;
  padding: 0;

  /* We want the view to always cover the full screen */
  min-height: 100vh;

  /* In landscape mode, don't scroll the body, just the sidebar */
  height: 100vh;

  background: var(--c-background);
  color: var(--c-flowtext);
  font-family: var(--flowtext-font);

  display: grid;
  gap: 0;
  place-items: stretch;

  /* Landscape layout has video/content side by side with the sidebar,
     sidebar may scroll vertically if content overflows */
  grid-template-areas:
    " header  header"
    "content  sidebar"
    " footer  footer";
  grid-template-rows: 4rem calc(100vh - 4rem - 4rem) 4rem;

  /* Two columns for content/sidebar */
  grid-template-columns: minmax(28rem, auto) minmax(20rem, 32rem);

  /* TODO: Content view should be expandable to full width, hiding
     the sidebar */

  /* Portrait mode or sufficiently narrow viewport */
  @media (max-aspect-ratio: 1/1),
         (max-width: 48rem) {
    /* Non-scrolling sidebar content */
    height: auto;

    /* In portrait layout video content is pinned to the top of the
       screen, the sidebar content is below and may scroll on overflow */
    grid-template-areas:
      "header    header"
      "content  content"
      "sidebar  sidebar"
      "footer    footer";
    grid-template-rows: 4rem 56.25vw /*16/9*/ auto 4rem;

    /* Single-column layout */
    grid-template-columns: auto 0;
  }
}
header {
  background: var(--c-header);
}
footer {
  background: var(--c-background-alt);
  color: var(--c-flowtext-alt);
}

.streambox__controls button {
  background: var(--c-decor);
}



.streambox,
.streambox__sidebar {
  background: transparent;
}

/* style.css | http://localhost:8080/style.css */

.streambox__sidebar {
  grid-area: sidebar;

  max-width: 32rem;

  /* The below properties only really affects landscape views
     on larger sizes only, in mobile view the sidebar is expanded
     to scroll together with the page content */

  /* Scroll long content */
  overflow-y: scroll;

  /* When centered, ensure long content doesn't overflow */
  max-height: 100%;
}

.sidebar-content h2,
.sidebar-content h3 {
  max-width: 80%;
  color: var(--c-title);
  display: inline-block;
}


.sidebar-content h2::after,
.sidebar-content h3::after {
  content: '';
  display: block;
	height: 12px;
	position: relative;
	top: 0;
	left: -10px;
	width: 120%;
	max-width: 90vw;
	line-height: 1.6;
	border-radius: 2px;
  background-color: var(--c-decor);

  margin-top: 5px;
}


.content button.sbc, .sidebar-content button.sbc, .home-button {
	/*display: inline-block;
	width: 100%;*/
	min-height: 38px;
	padding: 8px 30px;
	text-align: center;
	font-weight: 600;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	text-decoration: none;
	border-radius: 4px;
	border: 1px solid;
	cursor: pointer;
  box-sizing: border-box;
  border-color: transparent;

  &:not([disabled]) {
    &, &:hover {
      background-color: var(--c-decor);
      color: var(--c-highlight-text);
    }

    &:hover, &:focus {
      border-color: white;
    }
  }
}

.home-button {
  padding: .33rem .5rem;

  svg {
    height: 1rem;
  }
}

.main__content {
  grid-area: content;
  position: relative;
}

.streambox_video {
  --streambox-height: min(calc(100vh - 4rem - 8rem - 2rem), calc((100vw - 24rem)/16*9));
  max-width: unset;
  max-height: unset;
}

header {
  grid-area: header;
  text-align: center;
  height: 100%;
}

header img {
  width: auto;
  height: 100%;
}

header nav {
  position: absolute;
  right: 1rem;

  height: 4rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  a {
    font-family: var(--c-flowtext);
    color: var(--c-decor);
    font-weight: 600;
    text-decoration: none;
  }
}

footer {
  grid-area: footer;

  display: grid;
  place-items: center;
  text-align: center;

  font-family: var(--c-flowtext);
  a {
    color: var(--c-highlight);
    text-decoration: none;
  }
}

/* OPTIONAL: Center main content and sidebar vertically */
body > main {
  display: flex;
}
body > aside {
  place-self: center;
}
