a {
  color: var(--c-highlight);
}
:visited {
  color: none; /* this is invalid but works! */
}

a:hover,
a:active {
  color: var(--c-forms);
}
a:focus {
  outline-style: solid;
  outline-color: var(--c-forms);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--title-font);
  color: var(--c-decor);
  margin: 1em 0 .5em;
  line-height: 1.125;

  &:first-child {
    margin-top: 0;
  }
}

/* ----------------------
     CONTENT FORMATTING
   ----------------------- */

.content,
.sidebar-content {

  h1 {
    font-size: 2.25rem;
    margin: 1em 0;
  }
  h2 { font-size: 2rem; }
  h3 { font-size: 1.8rem; }
  h4 { font-size: 1.675rem; }
  h5 { font-size: 1.5rem; }

  p,
  ul, ol,
  blockquote {
    max-width: 48rem;

    font-family: var(--flowtext-font);
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.3;

    margin: 0 0 1rem;
  }

  blockquote {
    border-left: .5rem solid var(--c-gray-700);
    padding:  0 0 0 .875rem;
  }

  hr {
    margin: 4.5rem 0;
    border: none;
    border-bottom: 1px solid var(--c-gray-500);
  }

  ul, ol {
    list-style-position: outside;
    padding: 0 0 0 1em;
  }

  li {
    margin: .3em 0;
  }


  /* Styling of direct links (generated by the Markdown parser) */
  .direct-link {
    font-weight: bold;
    text-decoration: none;

    display: inline-block;
    position: absolute;
    left: -.9em;
    margin: -.2em;
    padding: .2em;

    opacity: 0;

    :focus > &,
    :hover > &,
    &:hover,
    &:focus {
      opacity: .4;
    }
  }

  /* Prepend an email icon to cemail contact links */
  a[href^="mailto:"] {
    /* prevent text wrapping */
    white-space: nowrap;

    /* icon */
    &::before {
      content: "";
      display: inline-block;

      /* TODO: import from source/figma? SVGO+https://yoksel.github.io/url-encoder/ */
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.6 5l-5.4 4c-.7.5-2 1.7-3.2 1.7-1.3 0-2.5-1.2-3.3-1.7L.3 5C.2 4.9 0 5 0 5v7.2c0 1 .7 1.7 1.7 1.7h14.6c1 0 1.7-.7 1.7-1.7V5.1c0-.1-.2-.2-.4-.1zM9 9.5c.8 0 2-1 2.6-1.4 4.6-3.4 5-3.7 6-4.6.3-.1.4-.4.4-.6v-.7c0-1-.8-1.7-1.7-1.7H1.7C.7.5 0 1.3 0 2.2v.7c0 .2.1.5.3.6 1 .9 1.4 1.2 6.1 4.6.6.4 1.8 1.4 2.6 1.4z' fill='%235BD2AB'/%3E%3C/svg%3E");
      background-repeat: no-repeat;

      position: relative;
      bottom: -2px;
      width: 18px;
      height: 14px;

      margin-right: .375em;
    }
  }

  /* Content buttons */
  button.sbc {
    background-color: var(--c-teal-400);
    border-style: none;
    /*chonky*/border-radius: .25rem .25rem .25rem .75rem;

    padding: .8rem 2rem;

    font-family: var(--title-font);
    font-size: 1.5rem;
    line-height: 1.6rem;
    color: --c-flowtext;
    text-transform: uppercase;

    cursor: pointer;

    /* Custom for sidebar-content */
    min-width: 10rem;
    margin: .5rem 0;

    &:active,
    &:hover {
      background-color: var(--c-teal-300);
    }
    &:focus {
      background-color: var(--c-magenta-300);
      outline: none;
    }

    &:disabled {
      background: var(--c-gray-800);
      color: var(--c-gray-500);
      cursor: default;
    }
  }
}

.sidebar-content {
  padding: 1em;
  /* Stretch padding on large screens */
  padding: max(2vw, .75rem);

  button.sbc {
    display: block;
    white-space: nowrap;
  }

  h1, h2, h3, h4, h5, h6,
  p, ul, ol, blockquote {
    max-width: 48rem;
  }
}

code {
  font-family: 'Roboto Mono';
  background: var(--c-background1);
  font-size: .93em;
  border-radius: .2em;
  padding: 0 .2em;
}