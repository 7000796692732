/* TODO: Technically this belongs to layout.css or a separate
   file connected to layout.css for video display (not just streaming)
*/
main > .video-js {
  /* Default sizing of the video, not ideal but results in no cropping */
  height: 100%;
  width: 100%;

  margin: auto;

  /* Modern browsers support calc()/min(), by updating the available
     width from JavaScript we make a mostly-CSS solution to the aspect
     ratio problem */
  /* height: min(100%, calc(var(--active-content-w) * 9 / 16)); */

  /* For really slim & wide viewports this is the equivalent of
     specifying a max-height: 100%, we clamp the width at the
     16:9 aspect ratio equivalent of the height minus header/footer */
  /* width: min(calc((100vh - 8rem) / 9 * 16), 100%); */

  /* For browsers that support aspect-ratio we can improve the
     video sizing without needing to rely on the --active-content-w
     set from JavaScript */
  @supports (aspect-ratio: 1/1) {
    /* aspect-ratio: 16/9; */
    /* height: auto; */
  }


  /* Blend the video player into the page background */
  background: var(--rust-blue);

  /* We will rely on the <video> element poster only */

  .vjs-poster {
    /*background-size: cover;*/
    display: none;
  }
}
