.ac {
	grid-row: 1;
	grid-column: auto/span calc(12/var(--acc));
	position: relative;
	width: 100%;
}
.ac:not([data-active="true"]) {
  grid-row: 2;
	grid-column: auto;
  place-self: center;
}
.ac .activeContentTrigger {
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	width: 100%;
	height: 3rem;
	background-color: rgba(255,255,255,.1);
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 2 12 7'%3E%3Cpath d='M2 2C1 2 0 3 0 4 0 5 4 9 6 9 8 9 12 5 12 4 12 3 11 2 10 2 9 2 8 5 6 5 4 5 3 2 2 2Z' fill='%23ffffff'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: 2rem;
	background-position: center;
	border: none;
	border-radius: 0;
	font-size: 0;
  cursor: pointer;
	transition: .4s all;
}
.ac .activeContentTrigger:hover {
	background-color: var(--c-purple-800)
}

.ac:not([data-active="true"]) .activeContentTrigger {
	display: block;
	background-image: none;
	background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 0;
  width: 8rem;
  height: 4rem;
  background-position: center;
  border: .5rem solid var(--c-purple-900);
  border-radius: .5rem;
	position: relative;
}
.ac:not([data-active="true"]) .content {
  display: none;
}

.ac:not([data-active="true"]) .activeContentTrigger.ex-huawei {
  background-image: url('/assets/ex/huawei/logo.png');
  background-color: var(--c-purple-900);
	position: static;
	height: 3rem;
}

.ac:not([data-active="true"]) .activeContentTrigger.stream {
	height: 100%;
}
.ac:not([data-active="true"]) .activeContentTrigger.fsb {
	color: white;
	font-size: .9rem;
	text-transform: uppercase;
	font-weight: bold;
	background: var(--c-purple-900);
}
